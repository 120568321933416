import { getUserRoles } from "../../api/roles";

export const FETCHING_USER_ROLES = "USER_ROLES/FETCHING_USER_ROLES";
export const USER_ROLES_FETCHED = "USER_ROLES/USER_ROLES_FETCHED";
export const USER_ROLES_FETCH_ERROR = "USER_ROLES/USER_ROLES_FETCH_ERROR";
export const UPDATE_USER_ROLES = "USER_ROLES/UPDATE_USER";
export const CLEAR_USER_ROLES = "USER_ROLES/CLEAR_USER_ROLES";

export const fetchUserRoles = userId => dispatch => {
  dispatch({
    type: FETCHING_USER_ROLES,
    payload: {
      userId
    }
  });

  getUserRoles(userId).then(
    roles => {
      dispatch({
        type: USER_ROLES_FETCHED,
        payload: {
          userId,
          roles
        }
      });
    },
    error => {
      dispatch({
        type: USER_ROLES_FETCH_ERROR,
        payload: {
          userId,
          error
        }
      });
    }
  );
};

export const updateUserRoles = (userId, roles) => ({
  type: UPDATE_USER_ROLES,
  payload: {
    userId,
    roles
  }
});

export const clearUserRoles = () => ({
  type: CLEAR_USER_ROLES
});
