import React from "react";

import Spinner from "./spinner";

import styles from "./spinner-overlay.module.scss";

const SpinnerOverlay = ({ ...props }) => (
  <div className={`bp-full-screen ${styles.overlay}`}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <Spinner dotClassName={styles.spinnerDot} {...props} />
  </div>
);

export default SpinnerOverlay;
