import React, { useState, useEffect, useRef, useCallback } from "react";

import IconUser from "../../../../assets/icons/user.svg";

import { useTranslations } from "../../../../hooks/translations";
import { useSelf } from "../../../../hooks/self";
import { useModalFocusHandler } from "../../../../hooks/modal-focus-handler";

import Button from "../../../button";
import Spinner from "../../../spinner";

import MobileHeader from "./mobile-header";
import Login from "./login";
import Logout from "./logout";

import styles from "./index.module.scss";

export default () => {
  const t = useTranslations("profilePopup");

  const wrapperRef = useRef();
  const buttonRef = useRef();
  const popupRef = useRef();

  const [isActive, setIsActive] = useState(false);
  const [isDialogVisible, setIsDialogVisible] = useState(false);

  const { initializing, user: currentUser } = useSelf();

  const toggleDialog = useCallback(() => setIsActive(!isActive), [setIsActive, isActive]);

  const closeDialog = useCallback(() => setIsActive(false), [setIsActive]);

  const handleTabKey = useModalFocusHandler(popupRef);

  /* eslint-disable consistent-return */
  useEffect(() => {
    if (!isActive) {
      return;
    }

    const closePopup = event => {
      if (!wrapperRef.current.contains(event.target)) {
        closeDialog();
      }
    };

    const onKeydown = event => {
      if (event.key === "Escape") {
        closeDialog();
        buttonRef.current.focus();
      }

      handleTabKey(event);
    };

    document.addEventListener("click", closePopup);
    document.addEventListener("keydown", onKeydown);

    return () => {
      document.removeEventListener("click", closePopup);
      document.removeEventListener("keydown", onKeydown);
    };
  }, [wrapperRef, buttonRef, isActive, closeDialog, handleTabKey]);
  /* eslint-enable consistent-return */

  useEffect(() => {
    const animationEndListener = () => {
      setIsDialogVisible(!!isActive);
    };

    popupRef.current.addEventListener("animationend", animationEndListener);

    return () => popupRef.current.removeEventListener("animationend", animationEndListener);
  }, [isActive, setIsDialogVisible]);

  return (
    <div className={`${styles.wrapper} ${isActive ? styles.active : null}`} ref={wrapperRef}>
      <Button
        className={`bp-navigation ${styles.button}`}
        aria-haspopup="dialog"
        aria-expanded={isActive}
        aria-label={t.myProfile}
        onClick={toggleDialog}
        ref={buttonRef}
      >
        <span className={`bp-center ${styles.iconWrapper}`}>
          <IconUser className="bp-stroke" />
        </span>
      </Button>

      <div
        className={styles.popupWrapper}
        role="dialog"
        aria-labelledby="profile-popup-heading"
        aria-hidden={!isActive}
        ref={popupRef}
      >
        <MobileHeader onClose={closeDialog} />

        <h1 className="bp-visually-hidden" id="profile-popup-heading">
          {currentUser ? t.loginToProfile : t.myProfile}
        </h1>

        {/* eslint-disable no-nested-ternary */}
        {initializing ? (
          <div className={styles.spinnerWrapper}>
            <Spinner />
          </div>
        ) : currentUser ? (
          <Logout onComplete={closeDialog} isVisible={isDialogVisible} />
        ) : (
          <Login onComplete={closeDialog} isVisible={isDialogVisible} />
        )}
        {/* eslint-enable no-nested-ternary */}
      </div>
    </div>
  );
};
