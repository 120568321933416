module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Rubik:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700;"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Baltosios Pirštinės","short_name":"Pirštinės","start_url":"/","background_color":"#f5f5f7","theme_color":"#2b2f3d","display":"minimal-ui","icon":"src/assets/images/logo-no-text.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"cc880ef1a291fe426c0ee0e0b33c402b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
