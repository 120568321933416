const canUseLocalStorage = typeof window !== "undefined" && window.localStorage;

export const store = (key, data) => canUseLocalStorage && window.localStorage.setItem(key, data);

export const get = key => {
  const dataString = canUseLocalStorage && window.localStorage.getItem(key);

  let data;
  try {
    data = JSON.parse(dataString);
  } catch (err) {
    console.log("Could not parse stored data", err);
  }

  return data;
};

export const remove = key => canUseLocalStorage && window.localStorage.removeItem(key);
