export const trimData = data => {
  const dataCopy = { ...data };
  Object.keys(dataCopy).forEach(key => {
    if (typeof dataCopy[key] === "string") {
      dataCopy[key] = dataCopy[key].trim();
    } else if (dataCopy[key] instanceof Date) {
      dataCopy[key] = dataCopy[key].toISOString();
    }
  });
  return dataCopy;
};
