import React from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";

import userReducers from "./users/user-reducers";
import selfReducers from "./self/self-reducers";
import userRolesReducers from "./user-roles/user-roles-reducers";
import violationReportsReducers from "./violation-reports/violation-report-reducers";

import Auth from "./auth";

const reducer = combineReducers({
  users: userReducers,
  self: selfReducers,
  userRoles: userRolesReducers,
  violationReports: violationReportsReducers
});

const initialState = {};

const store = createStore(reducer, initialState, applyMiddleware(thunk));

const StoreProvider = ({ children }) => (
  <Provider store={store}>
    <Auth>{children}</Auth>
  </Provider>
);

StoreProvider.propTypes = {
  children: PropTypes.node
};

StoreProvider.defaultProps = {
  children: null
};

export default StoreProvider;
