import React from "react";
import PropTypes from "prop-types";

import styles from "./spinner.module.scss";

const Spinner = ({ className, dotClassName }) => (
  <span className={`${styles.container} ${className}`}>
    <span className={`${styles.dot} ${dotClassName}`} />
    <span className={`${styles.dot} ${dotClassName}`} />
    <span className={`${styles.dot} ${dotClassName}`} />
    <span className={`${styles.dot} ${dotClassName}`} />
    <span className={`${styles.dot} ${dotClassName}`} />
    <span className={`${styles.dot} ${dotClassName}`} />
  </span>
);

Spinner.propTypes = {
  className: PropTypes.string,
  dotClassName: PropTypes.string
};

Spinner.defaultProps = {
  className: null,
  dotClassName: null
};

export default Spinner;
