import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";

import NAVIGATION from "../../../../content/navigation.json";

import IconArrow from "../../../assets/icons/arrow.svg";

import { useTranslations } from "../../../hooks/translations";

import ButtonLink from "../../button-link";
import Button from "../../button";

// import LanguageSwitcher from "./language-switcher";

import styles from "./header-navigation.module.scss";
import { isElectionOver } from "../../../utils/calendar";

const NavigationLink = ({ url, label, onKeyDown }) => (
  <ButtonLink to={url} className={`bp-navigation ${styles.navItem}`} onKeyDown={onKeyDown}>
    {label}
  </ButtonLink>
);

NavigationLink.propTypes = {
  url: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onKeyDown: PropTypes.func
};

NavigationLink.defaultProps = {
  onKeyDown: null
};

const NavigationSubMenu = ({ links, onTabOut }) => (
  <nav className={styles.subMenu}>
    {links.map(({ key, label, url }, index) => (
      <NavigationLink
        key={key}
        url={url}
        label={label}
        onKeyDown={event => {
          if (
            event.key === "Tab" &&
            ((event.shiftKey && index === 0) || (!event.shiftKey && index === links.length - 1))
          ) {
            onTabOut();
          }
        }}
      />
    ))}
  </nav>
);

NavigationSubMenu.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  onTabOut: PropTypes.func.isRequired
};

const SubMenuButton = ({ label, isActive, onClick, onTabBack }) => (
  <Button
    className={`bp-navigation ${styles.navItem}`}
    aria-haspopup="true"
    aria-expanded={isActive}
    onClick={onClick}
    onKeyDown={event => {
      if (event.key === "Tab" && event.shiftKey) {
        onTabBack();
      }
    }}
  >
    <IconArrow className="bp-stroke" />
    {label}
  </Button>
);

SubMenuButton.propTypes = {
  label: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onTabBack: PropTypes.func.isRequired
};

const NavigationButtonWithSubMenu = ({ label, isExpanded, onToggle, onSubMenuLeave, links }) => (
  <div className={`${styles.subMenuContainer} ${isExpanded ? styles.subMenuVisible : ""}`}>
    <SubMenuButton
      label={label}
      isActive={isExpanded}
      onClick={onToggle}
      onTabBack={onSubMenuLeave}
    />
    <NavigationSubMenu links={links} onTabOut={onSubMenuLeave} />
  </div>
);

NavigationButtonWithSubMenu.propTypes = {
  label: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
  onSubMenuLeave: PropTypes.func.isRequired,
  links: PropTypes.arrayOf(PropTypes.any).isRequired,
  isExpanded: PropTypes.bool
};

NavigationButtonWithSubMenu.defaultProps = {
  isExpanded: false
};

const HeaderNavigation = ({ isCalendarVisible, onCalendarToggle, className }) => {
  const t = useTranslations("navigation");

  // NOTE Forgive me for this part is rather complicated because I am lazy
  const [visibleSubMenuKey, setVisibleSubMenuKey] = useState(null);
  const [previousSubMenuKey, setPreviousSubMenuKey] = useState(null);

  const closeSubMenu = useCallback(() => {
    setPreviousSubMenuKey(visibleSubMenuKey);
    setVisibleSubMenuKey(null);
  }, [visibleSubMenuKey, setPreviousSubMenuKey, setVisibleSubMenuKey]);

  useEffect(() => {
    document.addEventListener("click", closeSubMenu, {
      capture: true
    });

    return () => {
      document.removeEventListener("click", closeSubMenu, {
        capture: true
      });
    };
  }, [closeSubMenu]);

  const toggleSubMenu = useCallback(
    subMenuKey => {
      if (previousSubMenuKey === subMenuKey && visibleSubMenuKey === null) {
        setPreviousSubMenuKey(null);
      } else {
        setPreviousSubMenuKey(visibleSubMenuKey);
        setVisibleSubMenuKey(subMenuKey);
      }
    },
    [previousSubMenuKey, visibleSubMenuKey, setPreviousSubMenuKey, setVisibleSubMenuKey]
  );

  return (
    <nav className={`${styles.navigation} ${className}`}>
      {isElectionOver() ? null : (
        <div className={styles.calendarToggleContainer}>
          <div className="bp-container">
            <button
              type="button"
              onClick={onCalendarToggle}
              className={`${styles.calendarToggle} ${isCalendarVisible && styles.calendarVisible}`}
              aria-controls="calendar"
              aria-expanded={isCalendarVisible}
            >
              <IconArrow className={`bp-stroke ${styles.arrow}`} />
              {isCalendarVisible ? t.hideCalendar : t.showCalendar}
            </button>
          </div>
        </div>
      )}

      {NAVIGATION.map(({ key, url, children }) =>
        url ? (
          <NavigationLink key={key} url={url} label={t[key]} />
        ) : (
          <NavigationButtonWithSubMenu
            key={key}
            label={t[key]}
            isExpanded={visibleSubMenuKey === key}
            onToggle={() => toggleSubMenu(key)}
            onSubMenuLeave={() => closeSubMenu(key)}
            links={children.map(child => ({
              ...child,
              label: t[child.key]
            }))}
          />
        )
      )}

      {/* Temporarily hidden */}
      {/* <LanguageSwitcher /> */}
    </nav>
  );
};

HeaderNavigation.propTypes = {
  isCalendarVisible: PropTypes.bool.isRequired,
  onCalendarToggle: PropTypes.func.isRequired,
  className: PropTypes.string
};

HeaderNavigation.defaultProps = {
  className: ""
};

export default HeaderNavigation;
