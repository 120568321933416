const coerceCssPixelValue = value => {
  if (value == null) {
    return "";
  }

  return typeof value === "string" ? value : `${value}px`;
};

const getParents = (element, parents = []) => {
  if (element.parentElement === null) {
    return parents;
  }

  return getParents(element.parentElement, parents.concat(element.parentElement));
};

const isElementScrollable = element => {
  const style = window.getComputedStyle(element, null);

  const overflow = style.getPropertyValue("overflow") + style.getPropertyValue("overflow-y");

  return element.scrollHeight > element.offsetHeight && /(auto|scroll)/.test(overflow);
};

// NOTE Based on https://stackoverflow.com/a/49186677/3744574
export const getScrollingParent = node => {
  const parents = [node].concat(getParents(node));

  return parents.find(isElementScrollable) || window;
};

// This algorithm is based on Angular's Material Dialog.
/* eslint-disable consistent-return */
export const blockScroll = () => {
  const html = document.documentElement;
  const documentRect = html.getBoundingClientRect();
  const { body } = document;
  const screenHeight = window.innerHeight;

  if (documentRect.height > screenHeight) {
    const cachedScrollPosition =
      -documentRect.top || body.scrollTop || window.scrollY || document.scrollTop || 0;

    html.style.top = coerceCssPixelValue(-cachedScrollPosition);

    html.classList.add("bp-no-scroll");

    return () => {
      html.classList.remove("bp-no-scroll");

      window.scroll({
        top: cachedScrollPosition,
        behavior: "auto"
      });
    };
  }
};
/* eslint-enable consistent-return */
