import React from "react";
import PropTypes from "prop-types";

import LocalizedLink from "./localized-link";

const ButtonLink = React.forwardRef((props, ref) => (
  <LocalizedLink {...props} className={`bp-button ${props.className || ""}`} ref={ref} />
));

ButtonLink.propTypes = {
  className: PropTypes.string
};

ButtonLink.defaultProps = {
  className: ""
};

export default ButtonLink;
