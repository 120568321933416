import TIME_UNIT from "../../enums/time-unit.json";

import { DAY_DURATION, HOUR_DURATION, MINUTE_DURATION } from "./time";

const getDaysInMonth = (year, month) => new Date(year, month + 1, 0).getDate();

export const getRemainingTime = targetDate => {
  const date = new Date();

  const currentYear = date.getFullYear();

  const currentMonth = date.getMonth();

  const monthDifference = targetDate.getMonth() - currentMonth;

  const fullCalendarMonthsRemaining = monthDifference - 1;

  let monthsRemaining = Math.max(fullCalendarMonthsRemaining, 0);

  let daysInRemainingFullCalendarMonths = 0;
  for (let index = 1; index <= fullCalendarMonthsRemaining; index += 1) {
    daysInRemainingFullCalendarMonths += getDaysInMonth(currentYear, currentMonth + index);
  }

  const timeRemainingSeconds = Math.round((targetDate.valueOf() - date.valueOf()) / 1000);

  const dayDifference = Math.floor(timeRemainingSeconds / DAY_DURATION);

  const hoursRemaining = Math.floor(
    (timeRemainingSeconds - dayDifference * DAY_DURATION) / HOUR_DURATION
  );

  const minutesRemaining = Math.floor(
    (timeRemainingSeconds - dayDifference * DAY_DURATION - hoursRemaining * HOUR_DURATION) /
      MINUTE_DURATION
  );

  const secondsRemaining = Math.floor(
    timeRemainingSeconds -
      dayDifference * DAY_DURATION -
      hoursRemaining * HOUR_DURATION -
      minutesRemaining * MINUTE_DURATION
  );

  let daysRemaining = dayDifference - daysInRemainingFullCalendarMonths;

  const daysInCurrentMonth = getDaysInMonth(currentYear, currentMonth);

  if (daysRemaining - daysInCurrentMonth >= 0) {
    monthsRemaining += 1;
    daysRemaining -= daysInCurrentMonth;
  }

  return {
    [TIME_UNIT.MONTH]: monthsRemaining,
    [TIME_UNIT.DAY]: daysRemaining,
    [TIME_UNIT.HOUR]: hoursRemaining,
    [TIME_UNIT.MINUTE]: minutesRemaining,
    [TIME_UNIT.SECOND]: secondsRemaining
  };
};
