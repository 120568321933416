import ELECTION_DAY from "../enums/election-day.json";

// =====SECOND PHASE START=====

export const ADULT_DATE = "2024-10-21T00:00:00";

export const REGISTRATION_DEADLINE = "2024-10-22T00:00:00";

export const ELECTION_STAGES = [
  {
    type: ELECTION_DAY.ADVANCE_MUNICIPALITY,
    start: "2024-10-22T00:00:00",
    end: "2024-10-24T00:00:00"
  },
  {
    type: ELECTION_DAY.ADVANCE_SPECIAL,
    start: "2024-10-23T00:00:00",
    end: "2024-10-25T00:00:00"
  },
  {
    type: ELECTION_DAY.ADVANCE_HOME,
    start: "2024-10-25T00:00:00",
    end: "2024-10-26T00:00:00"
  },
  {
    type: ELECTION_DAY.ELECTION_DAY,
    date: "2024-10-27T00:00:00"
  }
];

// =====SECOND PHASE END=====
