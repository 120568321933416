import { useContext, useMemo } from "react";

import SSRContext from "../ssr-context";

import { useLinkTranslation } from "./link-translation";

export const useIsRouteActive = (canonicalPath, { exact } = {}) => {
  const isSSR = useContext(SSRContext);

  const translatedPath = useLinkTranslation(canonicalPath);

  const regex = useMemo(
    () => new RegExp(exact ? `^${translatedPath}/?$` : `^${translatedPath}(/|$)`),
    [exact]
  );

  return !isSSR && regex.test(window.location.pathname);
};
