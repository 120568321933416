import { getViolationReports } from "../../api/violation-reports";

export const FETCHING_VIOLATION_REPORTS = "VIOLATION_REPORTS/FETCHING_VIOLATION_REPORTS";
export const VIOLATION_REPORTS_FETCHED = "VIOLATION_REPORTS/VIOLATION_REPORTS_FETCHED";
export const VIOLATION_REPORTS_FETCH_ERROR = "VIOLATION_REPORTS/VIOLATION_REPORTS_FETCH_ERROR";
export const UPDATE_VIOLATION_REPORT = "VIOLATION_REPORTS/UPDATE_VIOLATION_REPORT";
export const CLEAR_VIOLATION_REPORTS = "VIOLATION_REPORTS/CLEAR_VIOLATION_REPORTS";

export const fetchViolationReports = input => dispatch => {
  dispatch({
    type: FETCHING_VIOLATION_REPORTS
  });

  getViolationReports(input).then(
    reports => {
      dispatch({
        type: VIOLATION_REPORTS_FETCHED,
        reports
      });
    },
    error => {
      dispatch({
        type: VIOLATION_REPORTS_FETCH_ERROR,
        error
      });
    }
  );
};

export const updateViolationReport = (reportId, data) => ({
  type: UPDATE_VIOLATION_REPORT,
  payload: {
    reportId,
    data
  }
});

export const clearViolationReports = () => ({
  type: CLEAR_VIOLATION_REPORTS
});
