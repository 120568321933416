import React from "react";
import PropTypes from "prop-types";

import IconX from "../assets/icons/x.svg";
import IconCheck from "../assets/icons/check.svg";

import Modal from "./modal";

import styles from "./status-modal.module.scss";

const StatusModal = ({ error, heading, description, onClose }) => (
  <Modal
    onClose={onClose}
    backdropClassName={error ? styles.error : styles.success}
    closeButtonClassName={styles.closeButton}
  >
    <div className={`bp-center ${styles.statusIcon}`}>
      {error ? <IconX className="bp-stroke" /> : <IconCheck className="bp-stroke" />}
    </div>

    <h1 className={styles.heading}>{heading}</h1>

    <p className={styles.description}>{description}</p>
  </Modal>
);

StatusModal.propTypes = {
  heading: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  error: PropTypes.bool,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

StatusModal.defaultProps = {
  error: false,
  description: null
};

export default StatusModal;
