import { useContext, useMemo, useCallback } from "react";

import LocaleContext from "../locale-context";

import { useTranslations } from "./translations";

const translateLink = (canonicalPath, currentLocale, mainLocale, t) => {
  const url = new URL(canonicalPath, "http://dummy.com");

  let pureCanonicalPath = canonicalPath;

  if (url.hash) {
    pureCanonicalPath = pureCanonicalPath.replace(new RegExp(`${url.hash}$`), "");
  }

  if (url.search) {
    // Escaping the question mark here
    pureCanonicalPath = pureCanonicalPath.replace(new RegExp(`\\${url.search}$`), "");
  }

  const isRootPath = pureCanonicalPath.startsWith("/");

  const subpaths = pureCanonicalPath.split("/");

  let translatedPath = subpaths.map(subpath => t[subpath] || subpath).join("/");

  if (!mainLocale && isRootPath) {
    translatedPath = `/${currentLocale}${translatedPath}`;
  }

  return `${translatedPath}${url.search}${url.hash}`;
};

export const useLinkTranslation = canonicalPath => {
  const t = useTranslations("sitemap");

  const { locale: currentLocale, mainLocale } = useContext(LocaleContext);

  if (typeof canonicalPath !== "string") {
    return useCallback(path => translateLink(path, currentLocale, mainLocale, t), [
      currentLocale,
      mainLocale
    ]);
  }

  return useMemo(() => translateLink(canonicalPath, currentLocale, mainLocale, t), [
    canonicalPath,
    currentLocale,
    mainLocale
  ]);
};
