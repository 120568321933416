import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { fetchCoordinators } from "../state/self/self-actions";

const selfSelector = state => state.self;

export const useSelf = () => useSelector(selfSelector);

export const useCoordinators = () => {
  const {
    userData,
    coordinators: { items: coordinators, loading, error },
    initializing
  } = useSelf();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!initializing && !coordinators && userData) {
      dispatch(fetchCoordinators(userData.municipality));
    }
  }, [initializing, userData, coordinators]);

  return {
    loading,
    coordinators,
    error
  };
};
