import React, { useState, useEffect, useRef, useCallback, useContext } from "react";
import PropTypes from "prop-types";

import IconArrow from "../../../../assets/icons/arrow.svg";
import { useTranslations } from "../../../../hooks/translations";
import { EMAIL, REQUIRED } from "../../../../utils/validators";
import LoginContext from "../../../../login-context";

import Button from "../../../button";
import ButtonLink from "../../../button-link";
import Form from "../../../form";
import FormInput from "../../../form-input";
import SpinnerOverlay from "../../../spinner-overlay";
import withLogin from "../../../hoc/login";

import styles from "./login.module.scss";

const LOGIN_FIELDS = {
  email: {
    value: "",
    validators: [
      {
        validate: REQUIRED,
        message: "emailRequired"
      },
      {
        validate: email => !email || EMAIL(email),
        message: "emailFormat"
      }
    ]
  },
  password: {
    value: "",
    validators: [
      {
        validate: REQUIRED,
        message: "passwordRequired"
      }
    ]
  }
};

const Login = ({ onComplete, isVisible }) => {
  const t = useTranslations("login");

  const emailInputRef = useRef();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const login = useContext(LoginContext);

  const submitForm = useCallback(formData => {
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    login(formData)
      .then(onComplete)
      .finally(() => {
        setIsSubmitting(false);
      });
  }, []);

  useEffect(() => {
    if (isVisible) {
      emailInputRef.current.focus();
    }
  }, [emailInputRef, isVisible]);

  return (
    <>
      <div className={styles.formWrapper}>
        <Form fields={LOGIN_FIELDS} onSubmit={submitForm} errorMessages={t.errors}>
          {({ setFieldValue, fields }) => (
            <>
              <FormInput
                id="email"
                label={t.email}
                type="email"
                field={fields.email}
                onChange={setFieldValue}
                ref={emailInputRef}
              />

              <FormInput
                id="password"
                label={t.password}
                type="password"
                field={fields.password}
                onChange={setFieldValue}
              />

              <Button
                type="submit"
                className="bp-arrow-button bp-main-button"
                disabled={isSubmitting}
              >
                {t.submit}
                <IconArrow className="bp-stroke" />
              </Button>

              <br />

              <ButtonLink
                to="/register"
                className="bp-arrow-button bp-secondary-button"
                onClick={onComplete}
              >
                {t.register}
                <IconArrow className="bp-stroke" />
              </ButtonLink>

              <br />

              <ButtonLink
                to="/forgot-password"
                className="bp-arrow-button bp-secondary-button"
                onClick={onComplete}
                state={{ email: fields.email.value }}
              >
                {t.forgotPassword}
                <IconArrow className="bp-stroke" />
              </ButtonLink>
            </>
          )}
        </Form>
      </div>

      {isSubmitting ? <SpinnerOverlay className={styles.spinner} /> : null}
    </>
  );
};

Login.propTypes = {
  onComplete: PropTypes.func,
  isVisible: PropTypes.bool
};

Login.defaultProps = {
  onComplete: () => {},
  isVisible: false
};

export default withLogin(Login);
