import React from "react";
import PropTypes from "prop-types";

const SSRContext = React.createContext();

SSRContext.displayName = "SSR";

export default SSRContext;

export const InsideSSR = ({ children }) => (
  <SSRContext.Consumer>{build => build && children}</SSRContext.Consumer>
);

InsideSSR.propTypes = {
  children: PropTypes.node.isRequired
};

export const OutsideSSR = ({ children }) => (
  <SSRContext.Consumer>{build => !build && children}</SSRContext.Consumer>
);

OutsideSSR.propTypes = {
  children: PropTypes.node.isRequired
};
