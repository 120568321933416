import React, { useState, useCallback, useRef, useEffect, useMemo } from "react";
import PropTypes from "prop-types";

import { useTranslations } from "../hooks/translations";
import { useScrollIntoView } from "../hooks/scroll-into-view";

import { FORM_FIELD } from "../prop-types";

import styles from "./form.module.scss";

const copyFieldsObject = fields => {
  const fieldsCopy = {};

  Object.keys(fields).forEach(key => {
    fieldsCopy[key] = { ...fields[key] };
  });

  return fieldsCopy;
};

const Form = ({ children, fields, onSubmit, displayGlobalError, errorMessages, className }) => {
  const t = useTranslations("form");
  const formErrorsRef = useRef();
  const fieldsCopy = useMemo(() => copyFieldsObject(fields), [fields]);
  const [form, setForm] = useState(fieldsCopy);
  const [invalidFieldCount, setInvalidFieldCount] = useState(0);
  const [scrollToTop, setScrollToTop] = useState(false);
  const [displayFormError, setDisplayFormError] = useState(false);
  const [revalidateField, setRevalidateField] = useState(null);

  useEffect(() => {
    Object.entries(fieldsCopy).forEach(([key, field]) => {
      const fieldState = form[key];
      if (fieldState) {
        field.value = fieldState.value;
        field.error = null;
      }
    });
    setForm(fieldsCopy);
  }, [fieldsCopy]);

  const validateField = fieldState => {
    if (fieldState.validators) {
      const invalid = fieldState.validators.find(
        ({ validate }) => !validate(fieldState.value, form)
      );
      fieldState.error = invalid && errorMessages[invalid.message];
    }
  };

  const setFieldValue = useCallback(
    (fieldName, fieldState, newValue) => {
      const nextFieldState = { ...fieldState, value: newValue };

      validateField(nextFieldState);

      if (fieldState.error && !nextFieldState.error) {
        setInvalidFieldCount(invalidFieldCount - 1);

        if (invalidFieldCount === 1) {
          setDisplayFormError(false);
        }
      } else if (!fieldState.error && nextFieldState.error) {
        setInvalidFieldCount(invalidFieldCount + 1);
      }

      setForm({
        ...form,
        [fieldName]: nextFieldState
      });

      if (nextFieldState.revalidateField) {
        setRevalidateField(nextFieldState.revalidateField);
      }
    },
    [form, setForm, invalidFieldCount, setInvalidFieldCount, setRevalidateField]
  );

  useEffect(() => {
    if (revalidateField) {
      setFieldValue(revalidateField, form[revalidateField], form[revalidateField].value);
      setRevalidateField(null);
    }
  }, [revalidateField, form, setFieldValue, setRevalidateField]);

  const submitForm = useCallback(
    e => {
      e.preventDefault();

      Object.values(form).forEach(fieldState => {
        validateField(fieldState);
      });

      const invalidFields = Object.values(form).filter(({ error }) => !!error);

      if (invalidFields.length > 0) {
        setForm({ ...form });
        setInvalidFieldCount(invalidFields.length);
        setScrollToTop(true);
        setDisplayFormError(true);
        return;
      }

      setInvalidFieldCount(0);
      setScrollToTop(false);
      setDisplayFormError(false);
      const formValue = Object.entries(form).reduce((acc, [name, fieldState]) => {
        acc[name] = fieldState.value;
        return acc;
      }, {});

      const result = onSubmit(formValue);

      // Cannot check instanceof Promise because firebase does not return Promise instances
      if (result && typeof result.then === "function") {
        result.then(resetForm => {
          if (resetForm) {
            setForm(fields);
          }
        });
      }
    },
    [form, setForm, setInvalidFieldCount, onSubmit]
  );

  const scrollErrorsIntoView = displayGlobalError ? useScrollIntoView(formErrorsRef) : null;

  useEffect(() => {
    if (displayGlobalError && invalidFieldCount > 0 && displayFormError && scrollToTop) {
      scrollErrorsIntoView();
      setScrollToTop(false);
    }
  }, [invalidFieldCount, displayFormError, scrollToTop, setScrollToTop, scrollErrorsIntoView]);

  return (
    <>
      {displayGlobalError && (
        <div
          className={`${styles.formErrors} ${
            invalidFieldCount > 0 && displayFormError ? "" : "bp-hidden"
          }`}
          ref={formErrorsRef}
        >
          {t.invalid}
        </div>
      )}
      <form onSubmit={submitForm} className={`${styles.form} ${className}`}>
        {children({ setFieldValue, fields: form })}
      </form>
    </>
  );
};

Form.propTypes = {
  children: PropTypes.func.isRequired,
  fields: PropTypes.objectOf(FORM_FIELD.isRequired).isRequired,
  onSubmit: PropTypes.func.isRequired,
  errorMessages: PropTypes.objectOf(PropTypes.string.isRequired),
  displayGlobalError: PropTypes.bool,
  className: PropTypes.string
};

Form.defaultProps = {
  errorMessages: {},
  displayGlobalError: false,
  className: ""
};

export default Form;
