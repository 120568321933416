import { mapUserData } from "../../utils/user-mapper";
import { getMyCoordinators } from "../../api/volunteers";

export const CACHE_SELF = "USERS/CACHE_SELF";
export const UPDATE_SELF = "USERS/UPDATE_SELF";
export const CLEAR_SELF = "USERS/CLEAR_SELF";
export const FETCHING_COORDINATORS = "USERS/FETCHING_COORDINATORS";
export const COORDINATORS_FETCHED = "USERS/COORDINATORS_FETCHED";
export const COORDINATORS_FETCH_ERROR = "USERS/COORDINATORS_FETCH_ERROR";

export const cacheSelf = payload => ({
  type: CACHE_SELF,
  payload
});

export const updateSelf = data => (dispatch, getState) => {
  const { self } = getState();

  Object.keys(data).forEach(key => {
    if (typeof data[key] === "string") {
      data[key] = data[key].trim();
    }
  });

  dispatch({
    type: UPDATE_SELF,
    payload: mapUserData({
      ...self.userData.raw,
      ...data,
      updatedAt: new Date()
    })
  });
};

export const clearSelf = error => ({
  type: CLEAR_SELF,
  error
});

export const fetchCoordinators = municipality => dispatch => {
  dispatch({
    type: FETCHING_COORDINATORS
  });

  getMyCoordinators(municipality).then(
    coordinators => {
      dispatch({
        type: COORDINATORS_FETCHED,
        coordinators
      });
    },
    error => {
      dispatch({
        type: COORDINATORS_FETCH_ERROR,
        error
      });
    }
  );
};
