import { parseTimeString } from "./time";

const getValueLength = value =>
  (typeof value === "string" ? value.trim().length : value && value.length) || 0;

export const REQUIRED = value => (typeof value === "string" ? value.trim() : !!value);

export const EMAIL = value =>
  value &&
  /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/.test(
    value.trim()
  );

export const MAX_LENGTH = maxLength => value => getValueLength(value) <= maxLength;

export const PHONE = value => value && /^\d{7}$/.test(value.trim());

export const DATE = value => /^\d{4}-\d{2}-\d{2}$/.test(value) && !Number.isNaN(Date.parse(value));

export const MIN_LENGTH = minLength => value => getValueLength(value) >= minLength;

export const TIME = value => {
  const time = parseTimeString(value);

  if (!time) {
    return false;
  }

  const { hours, minutes } = time;

  return hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59;
};

export const PERSONAL_CODE = value => {
  if (typeof value !== "string" || !/^\d{11}$/.test(value)) {
    return false;
  }

  let s1 = 0;
  let s2 = 0;

  let coeffS1 = 1;
  let coeffS2 = 3;

  for (let i = 0; i < 10; i += 1) {
    const digit = parseInt(value[i], 10);

    s1 += coeffS1 * digit;
    s2 += coeffS2 * digit;

    coeffS1 += 1;
    if (coeffS1 === 10) {
      coeffS1 = 1;
    }

    coeffS2 += 1;
    if (coeffS2 === 10) {
      coeffS2 = 1;
    }
  }

  const modS1 = s1 % 11;
  const modS2 = s2 % 11;

  let controlDigit;

  if (modS1 === 10) {
    if (modS2 === 10) {
      controlDigit = 0;
    } else {
      controlDigit = modS2;
    }
  } else {
    controlDigit = modS1;
  }

  return parseInt(value[10], 10) === controlDigit;
};
