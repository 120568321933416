import { getUsers } from "../../api/volunteers";
import { mapUserData } from "../../utils/user-mapper";

export const FETCHING_USERS = "USERS/FETCHING_USERS";
export const USERS_FETCHED = "USERS/USERS_FETCHED";
export const USERS_FETCH_ERROR = "USERS/USERS_FETCH_ERROR";
export const UPDATE_USER = "USERS/UPDATE_USER";
export const CLEAR_USERS = "USERS/CLEAR_USERS";

export const fetchUsers = input => dispatch => {
  dispatch({
    type: FETCHING_USERS
  });

  getUsers(input).then(
    users => {
      dispatch({
        type: USERS_FETCHED,
        users
      });
    },
    error => {
      dispatch({
        type: USERS_FETCH_ERROR,
        error
      });
    }
  );
};

export const updateUser = (userId, data) => (dispatch, getState) => {
  const { users } = getState();

  const user = users.users.find(({ id }) => id === userId);

  Object.keys(data).forEach(key => {
    if (typeof data[key] === "string") {
      data[key] = data[key].trim();
    }
  });

  if (data.comment) {
    data.comments = user.comments ? user.comments.concat(data.comment) : [data.comment];
    delete data.comment;
  }

  dispatch({
    type: UPDATE_USER,
    payload: {
      userId,
      data: mapUserData({
        ...user.raw,
        ...data,
        updatedAt: new Date()
      })
    }
  });
};

export const clearUsers = () => ({
  type: CLEAR_USERS
});
