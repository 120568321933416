import {
  FETCHING_USER_ROLES,
  USER_ROLES_FETCHED,
  USER_ROLES_FETCH_ERROR,
  UPDATE_USER_ROLES,
  CLEAR_USER_ROLES
} from "./user-roles-actions";

export default (state = {}, action) => {
  switch (action.type) {
    case FETCHING_USER_ROLES:
      return {
        ...state,
        [action.payload.userId]: {
          loading: true
        }
      };
    case USER_ROLES_FETCHED:
      return {
        ...state,
        [action.payload.userId]: {
          loading: false,
          roles: action.payload.roles
        }
      };
    case USER_ROLES_FETCH_ERROR:
      return {
        ...state,
        [action.payload.userId]: {
          loading: false,
          roles: {},
          error: action.payload.error
        }
      };
    case UPDATE_USER_ROLES:
      return {
        ...state,
        [action.payload.userId]: {
          loading: false,
          roles: action.payload.roles
        }
      };
    case CLEAR_USER_ROLES:
      return {};
    default:
      return state;
  }
};
