import { useContext } from "react";
import TranslationsContext from "../translations-context";

export const useTranslations = path => {
  const translations = useContext(TranslationsContext);

  if (path) {
    try {
      return path.split(".").reduce((o, key) => o[key], translations);
    } catch (err) {
      return null;
    }
  }

  return translations;
};
