import { useCallback } from "react";

import { reportError } from "../utils/error-handler";
import { logout } from "../api/auth";

import { useLinkTranslation } from "./link-translation";
import { useNavigateLocalized } from "./navigate-localized";

export const useLogout = ({ callback, redirectTo = "/" }) => {
  const navigateToHome = useNavigateLocalized(redirectTo);

  const dashboardPath = useLinkTranslation("/dashboard");

  return useCallback(() => {
    if (callback) {
      callback();
    }

    if (new RegExp(`^${dashboardPath}(/|$)`).test(window.location.pathname)) {
      navigateToHome();
    }

    logout().catch(err => {
      reportError(err, "Failed to log out");
    });
  }, [navigateToHome, callback]);
};
