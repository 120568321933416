import React, { useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";

import InfoPopup from "./components/info-popup";

const InfoPopupContext = React.createContext();

InfoPopupContext.displayName = "InfoPopup";

export default InfoPopupContext;

export const InfoPopupProvider = ({ children }) => {
  const [popups, setPopups] = useState([]);

  const [popupCounter, setPopupCounter] = useState(0);

  const display = useCallback(popup => {
    setPopups([...popups, { ...popup, key: popupCounter }]);
    setPopupCounter(popupCounter + 1);
  }, []);

  const remove = useCallback(popup => {
    const popupIndex = popups.indexOf(popup);
    if (popupIndex > -1) {
      setPopups([...popups.slice(0, popupIndex), ...popups.slice(popupIndex + 1)]);
    }
  });

  const value = useMemo(
    () => ({
      display
    }),
    [display]
  );

  return (
    <InfoPopupContext.Provider value={value}>
      <>
        {children}
        {popups.map(popup => (
          <InfoPopup key={popup.key} error={popup.error} onClose={() => remove(popup)}>
            {popup.message}
          </InfoPopup>
        ))}
      </>
    </InfoPopupContext.Provider>
  );
};

InfoPopupProvider.propTypes = {
  children: PropTypes.node
};

InfoPopupProvider.defaultProps = {
  children: null
};
