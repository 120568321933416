import { useCallback, useRef, useEffect } from "react";

import { getScrollingParent } from "../utils/scrolling";

export const useScrollIntoView = elementRef => {
  const scrollingParentRef = useRef(null);

  useEffect(() => {
    scrollingParentRef.current = getScrollingParent(elementRef.current);
  }, []);

  return useCallback(() => {
    const element = elementRef.current;
    const scrollingParent = scrollingParentRef.current;

    if (scrollingParent === window) {
      window.scrollTo({
        top: element.getBoundingClientRect().top + window.pageYOffset - 100,
        behavior: "smooth"
      });
    } else {
      scrollingParent.scrollBy({
        top: element.getBoundingClientRect().top - scrollingParent.getBoundingClientRect().top - 30,
        behavior: "smooth"
      });
    }
  }, []);
};
