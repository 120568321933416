import React from "react";
import PropTypes from "prop-types";

import { FORM_FIELD } from "../prop-types";

import FormField from "./form-field";

/* eslint-disable react/jsx-props-no-spreading */
const FormInput = React.forwardRef(
  ({ id, label, placeholder, type, field, onChange, className, ...props }, ref) => (
    <FormField id={id} label={label} field={field}>
      <input
        type={type}
        placeholder={placeholder || label}
        id={id}
        name={id}
        value={field.value}
        onChange={e => onChange(id, field, e.target.value)}
        className={`${field.error ? "bp-error" : null} ${className}`}
        ref={ref}
        {...props}
      />
    </FormField>
  )
);
/* eslint-enable react/jsx-props-no-spreading */

FormInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  field: FORM_FIELD.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string
};

FormInput.defaultProps = {
  placeholder: "",
  type: "text",
  className: ""
};

export default FormInput;
