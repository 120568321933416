import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import styles from "./info-popup.module.scss";

const POPUP_DURATION = 3000;

const CLOSE_DURATION = 200;

const InfoPopup = ({ onClose, children, error }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    });

    setTimeout(() => {
      setVisible(false);

      setTimeout(() => {
        onClose();
      }, CLOSE_DURATION);
    }, POPUP_DURATION);
  }, []);

  return (
    <div className={`${styles.popup} ${visible ? styles.visible : ""}`} role="alert">
      <div className={`${styles.content} ${error ? styles.error : ""}`}>{children}</div>
    </div>
  );
};

InfoPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  error: PropTypes.bool
};

InfoPopup.defaultProps = {
  error: false
};

export default InfoPopup;
