import {
  FETCHING_USERS,
  USERS_FETCHED,
  USERS_FETCH_ERROR,
  UPDATE_USER,
  CLEAR_USERS
} from "./user-actions";

export default (
  state = {
    loading: true
  },
  action
) => {
  switch (action.type) {
    case FETCHING_USERS:
      return {
        ...state,
        loading: true
      };
    case USERS_FETCHED:
      return {
        users: action.users,
        loading: false
      };
    case USERS_FETCH_ERROR:
      return {
        users: null,
        loading: false,
        error: action.error
      };
    case UPDATE_USER:
      return {
        ...state,
        users: state.users.map(user =>
          user.id === action.payload.userId
            ? {
                ...user,
                ...action.payload.data
              }
            : user
        )
      };
    case CLEAR_USERS:
      return {
        loading: true
      };
    default:
      return state;
  }
};
