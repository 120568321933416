const padZero = value => (`${value}`.length < 2 ? `0${value}` : value);

export const MINUTE_DURATION = 60;

export const HOUR_DURATION = MINUTE_DURATION * 60;

export const DAY_DURATION = HOUR_DURATION * 24;

export const parseTimeString = value => {
  if (!value || typeof value !== "string") {
    return null;
  }

  const [hoursString, minutesString] = value.split(":");

  if (
    !hoursString ||
    hoursString.length > 2 ||
    hoursString.length < 1 ||
    !minutesString ||
    minutesString.length !== 2
  ) {
    return null;
  }

  const hours = parseInt(hoursString, 10);
  const minutes = parseInt(minutesString, 10);

  return {
    hours,
    minutes
  };
};

export const getLocalizedDateString = () => {
  const date = new Date();

  return `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(
    date.getDate()
  )} ${padZero(date.getHours())}.${padZero(date.getMinutes())}.${padZero(date.getSeconds())}`;
};

export const getISODateString = date => date.toISOString().slice(0, 10);
