/* eslint-disable */

import React, { useMemo, useEffect } from "react";
import PropTypes from "prop-types";

// NOTE It is crucial that global styles are loaded before anything else
import "./src/global-styles/index.scss";

import setupErrorReporting from "./src/utils/error-handler";
import Layout from "./src/components/layout/layout";
import LocaleContext from "./src/locale-context";
import LocationContext from "./src/location-context";
import SSRContext from "./src/ssr-context";
import TranslationsContext from "./src/translations-context";
import StoreProvider from "./src/state/store";
import { InfoPopupProvider } from "./src/info-popup-context";

const Page = ({ isSSR, element, props }) => {
  const {
    pageContext: { locale, mainLocale, translatedPath, canonicalPath },
    data: { translations }
  } = props;

  const isDashboard = useMemo(() => canonicalPath.match(/^\/dashboard\/$/), [canonicalPath]);

  useEffect(() => {
    setupErrorReporting();
  }, []);

  return (
    <SSRContext.Provider value={isSSR}>
      <LocaleContext.Provider
        value={{
          locale,
          mainLocale
        }}
      >
        <LocationContext.Provider
          value={{
            location: translatedPath,
            canonicalPath
          }}
        >
          <TranslationsContext.Provider value={translations}>
            <InfoPopupProvider>
              <StoreProvider>
                {isDashboard ? element : <Layout {...props}>{element}</Layout>}
              </StoreProvider>
            </InfoPopupProvider>
          </TranslationsContext.Provider>
        </LocationContext.Provider>
      </LocaleContext.Provider>
    </SSRContext.Provider>
  );
};

Page.propTypes = {
  element: PropTypes.node.isRequired,
  props: PropTypes.shape({
    pageContext: PropTypes.shape({
      locale: PropTypes.string.isRequired,
      mainLocale: PropTypes.bool,
      translatedPath: PropTypes.string.isRequired,
      canonicalPath: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  isSSR: PropTypes.bool
};

Page.defaultProps = {
  isSSR: false
};

export const getPageElementWrapper = isSSR => ({ element, props }) => (
  <Page isSSR={isSSR} element={element} props={props} />
);
