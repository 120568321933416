import database from "../firebase/firestore";
import callFunction from "../firebase/functions";
import { trimData } from "../utils/api";

const mapReportData = doc => {
  const data = doc.data();

  const { pollingStation, createdAt, partial } = data;

  const report = {
    id: doc.id,
    ...data,
    createdAt: createdAt.toDate(),
    createdAtString: createdAt.toDate().toLocaleString()
  };

  if (!partial) {
    Object.assign(report, {
      pollingStation: pollingStation ? pollingStation.name : ""
    });
  }

  return report;
};

export const getViolationReports = ({ orderBy = "createdAt" } = {}) =>
  database()
    .collection("reports")
    .orderBy(orderBy, "desc")
    .get()
    .then(({ docs }) => docs.map(mapReportData));

export const updateViolationReport = (reportId, data) =>
  callFunction("updateViolationReport", {
    reportId,
    ...trimData(data)
  });

export const getReportStatistics = observer => {
  database()
    .collection("report-statistics")
    .doc("stats")
    .onSnapshot(observer);
};
