import React from "react";
import PropTypes from "prop-types";

import styles from "./hamburger.module.scss";

/* eslint-disable react/jsx-props-no-spreading */
const Hamburger = ({ open, onClick, className, ...props }) => (
  <button
    className={`hamburger hamburger--chop ${open && "active"} ${className} ${styles.hamburger}`}
    type="button"
    onClick={onClick}
    aria-haspopup="true"
    aria-expanded={open}
    {...props}
  >
    <div className="inner">
      <span className="bar" />
      <span className="bar" />
      <span className="bar" />
    </div>
  </button>
);
/* eslint-enable react/jsx-props-no-spreading */

Hamburger.propTypes = {
  open: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string
};

Hamburger.defaultProps = {
  open: false,
  onClick: () => {},
  className: ""
};

export default Hamburger;
