import { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { reportError } from "../utils/error-handler";
import { onToken, getCurrentUser, getUserClaims } from "../api/auth";
import { getUser } from "../api/volunteers";
import { useSelf } from "../hooks/self";
import { useNavigateLocalized } from "../hooks/navigate-localized";

import { cacheSelf, clearSelf } from "./self/self-actions";
import { clearUsers } from "./users/user-actions";
import { clearViolationReports } from "./violation-reports/violation-report-actions";

const Auth = ({ children }) => {
  const [isInitialLogin, setIsInitialLogin] = useState(true);

  const dispatch = useDispatch();

  const clearSelfData = useCallback(
    error => {
      dispatch(clearSelf(error));
      dispatch(clearUsers());
      dispatch(clearViolationReports());
    },
    [dispatch]
  );

  useEffect(() => {
    return onToken(() => {
      const user = getCurrentUser();

      if (user) {
        Promise.all([getUser(user.uid), getUserClaims()])
          .then(
            ([userData, claims]) => {
              dispatch(
                cacheSelf({
                  user,
                  claims,
                  userData,
                  initializing: false,
                  error: null
                })
              );
            },
            error => {
              clearSelfData(error);
              reportError(error, "Failed to get user data / claims");
            }
          )
          .finally(() => {
            setIsInitialLogin(false);
          });
      } else {
        clearSelfData();
        setIsInitialLogin(false);
      }
    });
  }, []);

  const navigateToDashboard = useNavigateLocalized("/dashboard/");

  const { user: currentUser, initializing } = useSelf();

  const userId = currentUser && currentUser.uid;

  useEffect(() => {
    if (!initializing && userId && !isInitialLogin) {
      navigateToDashboard();
    }
  }, [userId]);

  return children;
};

Auth.propTypes = {
  children: PropTypes.node
};

Auth.defaultProps = {
  children: null
};

export default Auth;
