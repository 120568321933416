import React, { useMemo } from "react";
import PropTypes from "prop-types";

import logo from "../assets/images/logo.svg";
import { useIsRouteActive } from "../hooks/is-route-active";
import { useSelf } from "../hooks/self";
import { useLinkTranslation } from "../hooks/link-translation";
import { useTranslations } from "../hooks/translations";

import LocalizedLink from "./localized-link";

import styles from "./logo-link.module.scss";

const LogoLink = ({ children, ...props }) => {
  const t = useTranslations("logoLink");

  const { user } = useSelf();

  const isDashboard = useIsRouteActive("/dashboard");

  const linkRoute = useMemo(() => {
    if (!user || isDashboard) {
      return {
        path: "/",
        label: t.home
      };
    }
    return {
      path: "/dashboard/",
      label: t.dashboard
    };
  }, [user, isDashboard]);

  const translatedPath = useLinkTranslation(linkRoute.path);

  return (
    <LocalizedLink to={translatedPath} aria-label={linkRoute.label} {...props}>
      {children || <img src={logo} className={styles.img} height="48" alt="" />}
    </LocalizedLink>
  );
};

LogoLink.propTypes = {
  children: PropTypes.node
};

LogoLink.defaultProps = {
  children: null
};

export default LogoLink;
