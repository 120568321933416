import React from "react";
import PropTypes from "prop-types";

import Spinner from "./spinner";

import styles from "./button.module.scss";

/* eslint-disable react/button-has-type, react/jsx-props-no-spreading */

const Button = React.forwardRef(
  (
    { className, spinnerClassName, spinnerDotClassName, children, busy, disabled, ...props },
    ref
  ) => (
    <>
      <button
        className={`bp-button ${className} ${busy ? styles.busy : null}`}
        disabled={busy || disabled}
        ref={ref}
        {...props}
      >
        {busy && <Spinner className={spinnerClassName} dotClassName={spinnerDotClassName} />}
        <span className={styles.children}>{children}</span>
      </button>
    </>
  )
);

Button.propTypes = {
  className: PropTypes.string,
  spinnerClassName: PropTypes.string,
  spinnerDotClassName: PropTypes.string,
  children: PropTypes.node,
  busy: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(["button", "submit"])
};

Button.defaultProps = {
  className: "",
  spinnerClassName: "",
  spinnerDotClassName: "",
  children: null,
  busy: false,
  disabled: false,
  type: "button"
};

export default Button;
