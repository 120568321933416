import firebase from "gatsby-plugin-firebase";

let initialized;

const initialize = () => {
  if (process.env.GATSBY_FIREBASE_USE_EMULATORS === "true") {
    firebase.firestore().settings({
      host: "localhost:8080",
      ssl: false
    });
  }
};

const database = () => {
  if (!initialized) {
    initialize();
  }

  return firebase.app().firestore();
};

export default database;
