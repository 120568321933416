import FacebookIcon from "../src/assets/icons/facebook.svg";
import InstagramIcon from "../src/assets/icons/instagram.svg";

export const SOCIAL = [
  {
    key: "instagram",
    url: "https://www.instagram.com/baltosiospirstines/",
    Icon: InstagramIcon,
    label: "Instagram"
  },
  {
    key: "facebook",
    url: "https://www.facebook.com/BaltosiosPirstines/",
    Icon: FacebookIcon,
    label: "Facebook"
  }
];
