import React, { useState, useCallback, useContext } from "react";
import PropTypes from "prop-types";

import { login } from "../../api/auth";
import { useTranslations } from "../../hooks/translations";
import InfoPopupContext from "../../info-popup-context";
import LoginContext from "../../login-context";

import StatusModal from "../status-modal";

const LoginHandler = ({ children }) => {
  const t = useTranslations("login");

  const infoPopup = useContext(InfoPopupContext);

  const [loginError, setLoginError] = useState(null);

  const doLogin = useCallback(
    data =>
      login(data).then(
        () => {
          infoPopup.display({
            message: t.success
          });
        },
        err => {
          setLoginError(err.code === "auth/user-disabled" ? t.accountDisabled : t.loginFailed);
          throw err;
        }
      ),
    [infoPopup, setLoginError]
  );

  return (
    <LoginContext.Provider value={doLogin}>
      {children}

      {loginError ? (
        <StatusModal
          error
          heading={loginError.heading}
          description={loginError.description}
          onClose={() => setLoginError(false)}
        />
      ) : null}
    </LoginContext.Provider>
  );
};

LoginHandler.propTypes = {
  children: PropTypes.node
};

LoginHandler.defaultProps = {
  children: null
};

const withLogin = Component => ({ ...props }) => (
  <LoginHandler>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <Component {...props} />
  </LoginHandler>
);

export default withLogin;
