import { useCallback } from "react";

const FOCUSABLE_ELEMENTS_SELECTOR = "button, a, input";

export const useModalFocusHandler = modalRef =>
  useCallback(
    event => {
      if (event.key !== "Tab") {
        return;
      }

      const focusableElements = Array.from(
        modalRef.current.querySelectorAll(FOCUSABLE_ELEMENTS_SELECTOR)
      ).filter(element => element.offsetWidth > 0 && element.offsetHeight > 0);
      const firstFocusableElement = focusableElements[0];
      const lastFocusableElement = focusableElements[focusableElements.length - 1];
      const isFirstItemFocused = document.activeElement === firstFocusableElement;
      const isLastItemFocused = document.activeElement === lastFocusableElement;

      const shiftPressed = event.shiftKey;

      if (shiftPressed && isFirstItemFocused) {
        event.preventDefault();
        lastFocusableElement.focus();
      } else if (!shiftPressed && isLastItemFocused) {
        event.preventDefault();
        firstFocusableElement.focus();
      }
    },
    [modalRef]
  );
