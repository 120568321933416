import database from "../firebase/firestore";
import callFunction from "../firebase/functions";
import { mapUserData } from "../utils/user-mapper";
import { trimData } from "../utils/api";

const mapPrivateInfo = docs => {
  const privateInfo = {};

  docs.forEach(doc => {
    privateInfo[doc.id] = doc.data();
  });

  return privateInfo;
};

const mapFirestoreData = (doc, privateInfo = {}) => {
  const data = doc.data();

  const { birthDate, createdAt, updatedAt, schedulesUpdatedAt, availability, comments } = data;

  const { personalCode } = privateInfo;

  return mapUserData({
    id: doc.id,
    ...data,
    birthDate: birthDate.toDate(),
    createdAt: createdAt.toDate(),
    updatedAt: updatedAt ? updatedAt.toDate() : null,
    schedulesUpdatedAt: schedulesUpdatedAt ? schedulesUpdatedAt.toDate() : null,
    availability: availability
      ? availability.map(({ from, to }) => ({
          from: from ? from.toDate() : null,
          to: to ? to.toDate() : null
        }))
      : [],
    comments: comments
      ? comments.map(comment => ({
          ...comment,
          createdAt: comment.createdAt.toDate()
        }))
      : [],
    personalCode
  });
};

const mapUsersData = (users, privateInfo) =>
  users.map(doc => mapFirestoreData(doc, privateInfo[doc.id]));

const getPrivateInfo = () => {
  return database()
    .collectionGroup("private-user-info")
    .get()
    .then(({ docs }) => mapPrivateInfo(docs));
};

const prepareAvailability = data => {
  data.availability = data.availability.map(trimData);
};

export const getUsers = ({
  orderBy = "createdAt",
  withPrivateInfo = false,
  municipalities
} = {}) => {
  let query = database().collection("users");

  if (municipalities) {
    query = query.where("municipality", "in", municipalities);
  }

  const privateInfoQuery = withPrivateInfo ? getPrivateInfo() : {};

  return Promise.all([
    query.orderBy(orderBy, "desc").get(),
    privateInfoQuery
  ]).then(([{ docs }, privateInfo]) => mapUsersData(docs, privateInfo));
};

export const getUser = userId =>
  database()
    .collection("users")
    .doc(userId)
    .get()
    .then(mapFirestoreData);

export const updateUser = (userId, data) =>
  callFunction("updateUser", {
    userId,
    ...trimData(data)
  });

export const submitAdditionalInfo = data => {
  const trimmedData = trimData(data);
  prepareAvailability(trimmedData);
  return callFunction("submitAdditionalInfo", trimmedData);
};

export const updateSelf = data => {
  const trimmedData = trimData(data);
  prepareAvailability(trimmedData);
  return callFunction("updateSelf", trimmedData);
};

export const updateSecondPhase = (secondPhase = false) =>
  callFunction("updateSecondPhase", {
    secondPhase
  });

export const getMyCoordinators = municipality =>
  database()
    .collection("coordinators")
    .doc(municipality)
    .get()
    .then(doc => Object.values(doc.data() || {}));

export const getCertificateId = () => callFunction("getCertificateId").then(({ data }) => data);
