import React from "react";
import PropTypes from "prop-types";

import { FORM_FIELD } from "../prop-types";

import styles from "./form-field.module.scss";

const FormField = ({ id, label, field, children, labelId, labelClassName }) => {
  return (
    <div className={field.error ? styles.invalid : null}>
      <label
        htmlFor={id}
        id={labelId}
        className={`${field.required ? styles.required : ""} ${labelClassName}`}
      >
        {label}
      </label>
      {children}
      {field.error && <div className="bp-error-message">{field.error}</div>}
    </div>
  );
};

FormField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  field: FORM_FIELD.isRequired,
  children: PropTypes.node.isRequired,
  labelId: PropTypes.string,
  labelClassName: PropTypes.string
};

FormField.defaultProps = {
  labelId: null,
  labelClassName: ""
};

export default FormField;
