import { useCallback } from "react";
import { navigate } from "gatsby";

import { useLinkTranslation } from "./link-translation";

export const useNavigateLocalized = path => {
  const localizedPath = useLinkTranslation(path);

  return useCallback(options => navigate(localizedPath, options), [localizedPath]);
};
