import {
  FETCHING_VIOLATION_REPORTS,
  VIOLATION_REPORTS_FETCHED,
  VIOLATION_REPORTS_FETCH_ERROR,
  UPDATE_VIOLATION_REPORT,
  CLEAR_VIOLATION_REPORTS
} from "./violation-report-actions";

export default (
  state = {
    loading: true
  },
  action
) => {
  switch (action.type) {
    case FETCHING_VIOLATION_REPORTS:
      return {
        ...state,
        loading: true
      };
    case VIOLATION_REPORTS_FETCHED:
      return {
        reports: action.reports,
        loading: false
      };
    case VIOLATION_REPORTS_FETCH_ERROR:
      return {
        reports: null,
        loading: false,
        error: action.error
      };
    case UPDATE_VIOLATION_REPORT:
      return {
        ...state,
        reports: state.reports.map(report =>
          report.id === action.payload.reportId
            ? {
                ...report,
                ...action.payload.data
              }
            : report
        )
      };
    case CLEAR_VIOLATION_REPORTS:
      return {
        loading: true
      };
    default:
      return state;
  }
};
