const SITE_KEY = process.env.GATSBY_RECAPTCHA_SITE_KEY;

const RECAPTCHA_URL = `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`;

let loadPromise;
let readyPromise;

const loadReCAPTCHA = () =>
  new Promise(resolve => {
    const script = document.createElement("script");
    script.src = RECAPTCHA_URL;
    script.addEventListener("load", resolve, { once: true });
    document.head.append(script);
  });

const getReCAPTCHA = () => {
  if (!loadPromise) {
    loadPromise = loadReCAPTCHA().then(getReCAPTCHA);
    return loadPromise;
  }

  if (!readyPromise) {
    readyPromise = new Promise(resolve => {
      window.grecaptcha.ready(resolve);
    });
    return readyPromise;
  }

  return Promise.resolve();
};

export const generateReCAPTCHAToken = action =>
  getReCAPTCHA().then(() => window.grecaptcha.execute(SITE_KEY, { action }));
