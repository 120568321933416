import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import { useLinkTranslation } from "../hooks/link-translation";

const LocalizedLink = React.forwardRef(({ to: canonicalPath, ...props }, ref) => (
  <Link {...props} to={useLinkTranslation(canonicalPath)} ref={ref} />
));

LocalizedLink.propTypes = {
  to: PropTypes.string.isRequired
};

export default LocalizedLink;
