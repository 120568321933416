import callFunction from "../firebase/functions";

export const reportError = (error, comment) => {
  callFunction(
    "captureError",
    error instanceof Error
      ? {
          type: error.name,
          message: error.message,
          stack: error.stack,
          code: error.code,
          comment
        }
      : {
          error,
          comment
        }
  ).catch(e => {
    console.error("Failed to report unhandled error", error, e);
  });
};

const setupErrorReporting = () => {
  if (typeof window === "undefined") {
    return;
  }

  window.addEventListener("error", ({ error }) => {
    reportError(error);
    return false;
  });

  window.addEventListener("unhandledrejection", ({ reason }) => {
    reportError(reason);
  });
};

export default setupErrorReporting;
