import {
  CACHE_SELF,
  UPDATE_SELF,
  CLEAR_SELF,
  FETCHING_COORDINATORS,
  COORDINATORS_FETCHED,
  COORDINATORS_FETCH_ERROR
} from "./self-actions";

const INITIAL_STATE = {
  user: null,
  claims: {},
  userData: null,
  coordinators: {
    loading: true
  },
  initializing: true,
  error: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CACHE_SELF:
      return {
        ...action.payload,
        coordinators: state.coordinators
      };
    case UPDATE_SELF:
      return {
        ...state,
        userData: {
          ...state.userData,
          ...action.payload
        }
      };
    case CLEAR_SELF:
      return {
        ...INITIAL_STATE,
        coordinators: state.coordinators,
        initializing: false,
        error: action.error
      };
    case FETCHING_COORDINATORS:
      return {
        ...state,
        coordinators: {
          ...state.coordinators,
          loading: true
        }
      };
    case COORDINATORS_FETCHED:
      return {
        ...state,
        coordinators: {
          loading: false,
          items: action.coordinators
        }
      };
    case COORDINATORS_FETCH_ERROR:
      return {
        ...state,
        coordinators: {
          loading: false,
          items: [],
          error: action.error
        }
      };
    default:
      return state;
  }
};
