import database from "../firebase/firestore";
import callFunction from "../firebase/functions";
import { trimData } from "../utils/api";

const mapUserRoles = doc => {
  const data = doc.data();

  return {
    roles: (data && data.roles) || [],
    municipalities: (data && data.municipalities) || []
  };
};

export const getUserRoles = userId =>
  database()
    .collection("user-roles")
    .doc(userId)
    .get()
    .then(mapUserRoles);

export const updateUserRoles = (userId, data) =>
  callFunction("updateUserRoles", {
    userId,
    ...trimData(data)
  });
