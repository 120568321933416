import PropTypes from "prop-types";

export const FORM_FIELD = PropTypes.shape({
  value: PropTypes.any,
  validators: PropTypes.arrayOf(
    PropTypes.shape({
      validate: PropTypes.func.isRequired,
      message: PropTypes.string.isRequired
    })
  ),
  error: PropTypes.string
});
