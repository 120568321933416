export const DONATION_TYPE = {
  MONTHLY: "monthly",
  SINGLE: "single",
  INCOME_TAX: "incomeTax"
};

export const DONATIONS = {
  [DONATION_TYPE.MONTHLY]: {
    amounts: [
      {
        amount: 1
      },
      {
        amount: 3
      },
      {
        amount: 10
      },
      {
        amount: 0
      }
    ],
    paymentOptions: [
      {
        name: "aukok.lt"
      },
      {
        name: "PayPal"
      },
      {
        name: "Patreon"
      }
    ]
  },
  [DONATION_TYPE.SINGLE]: {
    amounts: [
      {
        amount: 5
      },
      {
        amount: 10
      },
      {
        amount: 20
      },
      {
        amount: 0
      }
    ],
    paymentOptions: [
      {
        name: "aukok.lt"
      },
      {
        name: "PayPal"
      },
      {
        name: "Patreon"
      }
    ]
  },
  [DONATION_TYPE.INCOME_TAX]: {}
};

export const AUKOK_URL = "https://www.aukok.lt/projektai/Uz-skaidrius-ir-saziningus-Seimo-rinkimus";

export const GPM_URL = "https://www.vmi.lt/evmi/paramos-skyrimas?lang=lt";

export const KO_FI_URL =
  "https://ko-fi.com/baltosiospirstines/?hidefeed=true&widget=true&embed=true&preview=true";

export const PHONE = 1490;

export const PHONE_AMOUNT_EUR = 3;
