import firebase from "gatsby-plugin-firebase";

import { generateReCAPTCHAToken } from "./recaptcha";

const PUBLIC_FUNCTIONS = [
  "register",
  "subscribe",
  "submitPartialViolationReport",
  "submitViolationReport",
  "createNewAccount"
];

const USE_EMULATOR = process.env.GATSBY_FIREBASE_USE_EMULATORS === "true";

const FUNCTIONS_REGION = USE_EMULATOR ? undefined : "europe-west1";

const EMULATOR_HOST = "http://localhost:5001";

let functions;

const initialize = () => {
  functions = firebase.app().functions(FUNCTIONS_REGION);

  if (USE_EMULATOR) {
    functions.useFunctionsEmulator(EMULATOR_HOST);
  }
};

const isReCAPTCHARequired = functionName => PUBLIC_FUNCTIONS.includes(functionName);

const applyReCAPTCHA = functionName =>
  isReCAPTCHARequired(functionName) ? generateReCAPTCHAToken(functionName) : Promise.resolve();

const callFunction = (functionName, data, options) => {
  if (!functions) {
    initialize();
  }

  const apiFunction = functions.httpsCallable(functionName, options);

  return applyReCAPTCHA(functionName).then(token =>
    apiFunction(token ? { ...data, recaptcha: { token, action: functionName } } : data)
  );
};

export default callFunction;
