import auth from "../firebase/auth";

export const login = ({ email, password }) => auth().signInWithEmailAndPassword(email, password);

export const getCurrentUser = () => auth().currentUser;

export const getUserClaims = () =>
  getCurrentUser()
    .getIdTokenResult()
    .then(({ claims }) => claims);

export const logout = () => auth().signOut();

export const onToken = (...args) => auth().onIdTokenChanged(...args);

export const sendPasswordResetEmail = email => auth().sendPasswordResetEmail(email);

export const verifyPasswordResetCode = code => auth().verifyPasswordResetCode(code);

export const resetPassword = ({ code, password }) => auth().confirmPasswordReset(code, password);
