// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-contacts-jsx": () => import("./../../../src/pages/about/contacts.jsx" /* webpackChunkName: "component---src-pages-about-contacts-jsx" */),
  "component---src-pages-about-history-jsx": () => import("./../../../src/pages/about/history.jsx" /* webpackChunkName: "component---src-pages-about-history-jsx" */),
  "component---src-pages-about-partners-jsx": () => import("./../../../src/pages/about/partners.jsx" /* webpackChunkName: "component---src-pages-about-partners-jsx" */),
  "component---src-pages-about-reports-jsx": () => import("./../../../src/pages/about/reports.jsx" /* webpackChunkName: "component---src-pages-about-reports-jsx" */),
  "component---src-pages-about-team-jsx": () => import("./../../../src/pages/about/team.jsx" /* webpackChunkName: "component---src-pages-about-team-jsx" */),
  "component---src-pages-about-transparency-jsx": () => import("./../../../src/pages/about/transparency.jsx" /* webpackChunkName: "component---src-pages-about-transparency-jsx" */),
  "component---src-pages-account-action-jsx": () => import("./../../../src/pages/account-action.jsx" /* webpackChunkName: "component---src-pages-account-action-jsx" */),
  "component---src-pages-dashboard-jsx": () => import("./../../../src/pages/dashboard.jsx" /* webpackChunkName: "component---src-pages-dashboard-jsx" */),
  "component---src-pages-donate-jsx": () => import("./../../../src/pages/donate.jsx" /* webpackChunkName: "component---src-pages-donate-jsx" */),
  "component---src-pages-forgot-password-jsx": () => import("./../../../src/pages/forgot-password.jsx" /* webpackChunkName: "component---src-pages-forgot-password-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-new-account-jsx": () => import("./../../../src/pages/new-account.jsx" /* webpackChunkName: "component---src-pages-new-account-jsx" */),
  "component---src-pages-news-jsx": () => import("./../../../src/pages/news.jsx" /* webpackChunkName: "component---src-pages-news-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-register-jsx": () => import("./../../../src/pages/register.jsx" /* webpackChunkName: "component---src-pages-register-jsx" */),
  "component---src-pages-report-violation-jsx": () => import("./../../../src/pages/report-violation.jsx" /* webpackChunkName: "component---src-pages-report-violation-jsx" */),
  "component---src-pages-statistics-jsx": () => import("./../../../src/pages/statistics.jsx" /* webpackChunkName: "component---src-pages-statistics-jsx" */),
  "component---src-pages-violations-jsx": () => import("./../../../src/pages/violations.jsx" /* webpackChunkName: "component---src-pages-violations-jsx" */),
  "component---src-pages-volunteering-jsx": () => import("./../../../src/pages/volunteering.jsx" /* webpackChunkName: "component---src-pages-volunteering-jsx" */)
}

