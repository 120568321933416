import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useWindowWidth } from "@react-hook/window-size";

import NAVIGATION from "../../../../content/navigation.json";

import IconCalendar from "../../../assets/icons/calendar.svg";

import { BREAKPOINTS } from "../../../global-styles/breakpoints";
import { blockScroll } from "../../../utils/scrolling";
import { useTranslations } from "../../../hooks/translations";

import ButtonLink from "../../button-link";
import Hamburger from "../../hamburger";
import LogoLink from "../../logo-link";

// import LanguageSwitcherMobile from "./language-switcher-mobile";

import styles from "./header-navigation-mobile.module.scss";
import { isElectionOver } from "../../../utils/calendar";

const NavigationLink = ({ label, url, onClick }) => (
  <li>
    <ButtonLink to={url} className={`bp-navigation ${styles.navItem}`} onClick={onClick}>
      {label}
    </ButtonLink>
  </li>
);

NavigationLink.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

NavigationLink.defaultProps = {
  onClick: null
};

const HeaderNavigationMobile = ({ isCalendarVisible, onCalendarToggle, className }) => {
  const t = useTranslations("navigation");

  const [menuVisible, setMenuVisible] = useState(false);

  const windowWidth = useWindowWidth();

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  /* eslint-disable consistent-return */
  useEffect(() => {
    if ((isCalendarVisible || menuVisible) && windowWidth < BREAKPOINTS.LG) {
      return blockScroll();
    }
  }, [isCalendarVisible, menuVisible, windowWidth]);
  /* eslint-enable consistent-return */

  return (
    <nav className={`${styles.navigation} ${className}`}>
      <Hamburger open={menuVisible} onClick={toggleMenu} className={styles.hamburger} />

      <div className={`bp-section-alternative ${styles.menu} ${menuVisible && styles.visible}`}>
        <div className={styles.header}>
          <div className={`bp-container ${styles.headerContainer}`}>
            <LogoLink onClick={toggleMenu} />

            {/* Temporarily hidden */}
            {/* 
              <div className={`bp-center ${styles.languageSwitcher}`}>
                <LanguageSwitcherMobile onLanguageSwitch={toggleMenu} />
              </div>
            */}
          </div>
        </div>

        <div className={styles.scrollingContainer}>
          <ul className="bp-container">
            {isElectionOver() ? null : (
              <li className={styles.calendar}>
                <button
                  type="button"
                  className={styles.soloLink}
                  onClick={() => {
                    toggleMenu();
                    onCalendarToggle();
                  }}
                >
                  <IconCalendar className="bp-fill" /> {t.calendar}
                </button>
              </li>
            )}

            {NAVIGATION.map(({ key, url, children }) =>
              url ? (
                <li key={key}>
                  <NavigationLink
                    label={t[key]}
                    url={url}
                    className={styles.soloLink}
                    onClick={toggleMenu}
                  />
                </li>
              ) : (
                <li key={key}>
                  <h1>{t[key]}</h1>

                  <ul>
                    {children.map(child => (
                      <NavigationLink
                        key={child.key}
                        label={t[child.key]}
                        url={child.url}
                        onClick={toggleMenu}
                      />
                    ))}
                  </ul>
                </li>
              )
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

HeaderNavigationMobile.propTypes = {
  isCalendarVisible: PropTypes.bool.isRequired,
  onCalendarToggle: PropTypes.func.isRequired,
  className: PropTypes.string
};

HeaderNavigationMobile.defaultProps = {
  className: ""
};

export default HeaderNavigationMobile;
