import React from "react";
import PropTypes from "prop-types";

import IconX from "../../../../assets/icons/x.svg";

import { useTranslations } from "../../../../hooks/translations";
import LogoLink from "../../../logo-link";

import styles from "./mobile-header.module.scss";

const MobileHeader = ({ onClose }) => {
  const t = useTranslations("profilePopup");

  return (
    <div className={`bp-between ${styles.mobileHeader}`}>
      <LogoLink onClick={onClose} />

      <button type="button" className={styles.closeMobile} aria-label={t.close} onClick={onClose}>
        <IconX className="bp-stroke" />
      </button>
    </div>
  );
};

MobileHeader.propTypes = {
  onClose: PropTypes.func
};

MobileHeader.defaultProps = {
  onClose: null
};

export default MobileHeader;
