import React, { useState, useEffect, useRef } from "react";

import { useSelf } from "../../../hooks/self";
import { useTranslations } from "../../../hooks/translations";
import * as Storage from "../../../storage";
import { isLastDayToRegister } from "../../../utils/calendar";

import ButtonLink from "../../button-link";
import Modal from "../../modal";

import styles from "./index.module.scss";

const REGISTRATION_DEADLINE_MODAL_HIDDEN_STORAGE_KEY = "registration-deadline-modal-hidden";

const RegistrationDeadlineModal = () => {
  const t = useTranslations("registrationDeadline");

  const { initializing, user } = useSelf();
  const [isModalVisible, setModalVisible] = useState(false);

  const registerLinkRef = useRef();

  const hideModal = () => {
    setModalVisible(false);
    Storage.store(REGISTRATION_DEADLINE_MODAL_HIDDEN_STORAGE_KEY, true);
  };

  useEffect(() => {
    // Modal is hidden by default and if this record exists, let it stay hidden
    if (Storage.get(REGISTRATION_DEADLINE_MODAL_HIDDEN_STORAGE_KEY)) {
      return;
    }

    // Hide the modal and never show it again after a user logs in on this computer
    if (user) {
      hideModal();
    }

    // Otherwise, display the modal if it's the last day to register, the app is
    // initialized and the user is not logged in
    setModalVisible(isLastDayToRegister() && !initializing && !user);
  }, [initializing, user]);

  useEffect(() => {
    if (isModalVisible) {
      registerLinkRef.current.focus();
    }
  }, [registerLinkRef, isModalVisible]);

  return isModalVisible ? (
    <Modal onClose={hideModal} className={styles.modal}>
      <h1>{t.heading}</h1>

      <p className={styles.description}>{t.message}</p>

      <ButtonLink to="/register" ref={registerLinkRef} onClick={hideModal} className="bp-standard">
        {t.cta}
      </ButtonLink>
    </Modal>
  ) : (
    ""
  );
};

export default RegistrationDeadlineModal;
