import React, { useEffect, useRef, useState, useCallback } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

import IconX from "../assets/icons/x.svg";

import { blockScroll } from "../utils/scrolling";
import { useModalFocusHandler } from "../hooks/modal-focus-handler";

import styles from "./modal.module.scss";

// NOTE I am disabling the below rules because the div's with the click handlers
// are there only for the mouse-enabled users. This is intended.
/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/no-noninteractive-element-interactions */

const Modal = ({ onClose, children, className, backdropClassName, closeButtonClassName }) => {
  const [visible, setVisible] = useState(false);

  const modalRef = useRef();

  const previousActiveElementRef = useRef(document.activeElement);

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    });
  }, []);

  const closeModal = useCallback(
    returnValue => {
      setVisible(false);

      setTimeout(() => {
        onClose(returnValue);
        if (previousActiveElementRef.current) {
          previousActiveElementRef.current.focus();
        }
      }, 200);
    },
    [onClose, setVisible]
  );

  const handleTabKey = useModalFocusHandler(modalRef);

  const stopEvent = useCallback(event => {
    if (event.nativeEvent) {
      event.nativeEvent.stopImmediatePropagation();
    }
    event.stopPropagation();
  }, []);

  const executeCloseModal = useCallback(
    event => {
      stopEvent(event);
      closeModal();
    },
    [stopEvent, closeModal]
  );

  useEffect(() => {
    const keydownListener = event => {
      if (event.key === "Escape") {
        executeCloseModal(event);
      }

      handleTabKey(event);
    };

    document.addEventListener("keydown", keydownListener, true);

    return () => {
      document.removeEventListener("keydown", keydownListener, true);
    };
  }, [stopEvent, executeCloseModal, handleTabKey, modalRef]);

  return (
    <div
      className={`bp-center ${styles.backdrop} ${backdropClassName} ${
        visible ? styles.visible : null
      }`}
      onClick={executeCloseModal}
    >
      <div
        className={`${styles.modal} ${className}`}
        role="alert"
        onClick={stopEvent}
        ref={modalRef}
      >
        {/* eslint-disable jsx-a11y/no-autofocus */}
        <button
          type="button"
          className={`${styles.closeButton} ${closeButtonClassName}`}
          onClick={executeCloseModal}
          autoFocus
        >
          <IconX className="bp-stroke" />
        </button>
        {/* eslint-enable jsx-a11y/no-autofocus */}

        <section className={styles.section}>
          {typeof children === "function"
            ? children({
                closeModal
              })
            : children}
        </section>
      </div>
    </div>
  );
};

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  className: PropTypes.string,
  backdropClassName: PropTypes.string,
  closeButtonClassName: PropTypes.string
};

Modal.defaultProps = {
  children: null,
  className: "",
  backdropClassName: "",
  closeButtonClassName: ""
};

export default props => {
  useEffect(() => blockScroll(), []);

  // eslint-disable-next-line react/jsx-props-no-spreading
  return ReactDOM.createPortal(<Modal {...props} />, document.body);
};
