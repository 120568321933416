import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

import IconArrow from "../../../../assets/icons/arrow.svg";

import { useTranslations } from "../../../../hooks/translations";
import { useSelf } from "../../../../hooks/self";
import { useLogout } from "../../../../hooks/logout";

import Button from "../../../button";
import ButtonLink from "../../../button-link";

import styles from "./logout.module.scss";

const Logout = ({ onComplete, isVisible }) => {
  const t = useTranslations("logout");

  const dashboardLinkRef = useRef();

  const { user } = useSelf();

  useEffect(() => {
    if (isVisible) {
      dashboardLinkRef.current.focus();
    }
  }, [dashboardLinkRef, isVisible]);

  const logout = useLogout({
    callback: onComplete
  });

  return (
    <>
      <p className={styles.displayName}>{user.displayName}</p>

      <ButtonLink
        to="/dashboard/"
        className={`bp-arrow-button bp-main-button ${styles.button} ${styles.mainButton}`}
        onClick={onComplete}
        ref={dashboardLinkRef}
      >
        {t.dashboard}
        <IconArrow className="bp-stroke" />
      </ButtonLink>

      <br />

      <Button className={`bp-arrow-button bp-secondary-button ${styles.button}`} onClick={logout}>
        {t.logout}
        <IconArrow className="bp-stroke" />
      </Button>
    </>
  );
};

Logout.propTypes = {
  onComplete: PropTypes.func,
  isVisible: PropTypes.bool
};

Logout.defaultProps = {
  onComplete: () => {},
  isVisible: false
};

export default Logout;
