import React from "react";
import PropTypes from "prop-types";

import { SOCIAL } from "../../content/social";

import styles from "./social-links.module.scss";

const SocialLink = ({ url, label, Icon, className, withLabel }) => (
  <a
    href={url}
    aria-label={label}
    target="_blank"
    rel="noreferrer noopener nofollow"
    className={`bp-link ${className}`}
  >
    <Icon className="bp-stroke" />
    {withLabel && <span className={styles.label}>{label}</span>}
  </a>
);

SocialLink.propTypes = {
  url: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  Icon: PropTypes.func.isRequired,
  className: PropTypes.string,
  withLabel: PropTypes.bool
};

SocialLink.defaultProps = {
  className: "",
  withLabel: false
};

const SocialLinks = ({ className, linkClassName, withLabel }) => (
  <p className={`${styles.container} ${className}`}>
    {SOCIAL.map(({ key, url, label, Icon }) => (
      <SocialLink
        key={key}
        url={url}
        label={label}
        Icon={Icon}
        className={linkClassName}
        withLabel={withLabel}
      />
    ))}
  </p>
);

SocialLinks.propTypes = {
  className: PropTypes.string,
  linkClassName: PropTypes.string,
  withLabel: PropTypes.bool
};

SocialLinks.defaultProps = {
  className: "",
  linkClassName: "",
  withLabel: false
};

export default SocialLinks;
